<!-- =========================================================================================
  File Name: UserEdit.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-edit">

    <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'page-user-list'}" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="user_data">

      <div slot="no-body" class="tabs-container px-6 pt-6">

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Account" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <user-edit-tab-account class="mt-4" :data="user_data" />
            </div>
          </vs-tab>
          <vs-tab label="Qualifications" disabled icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <user-edit-tab-information class="mt-4" :data="user_data" />
            </div>
          </vs-tab>
          <vs-tab label="Availabilities" disabled icon-pack="feather" icon="icon-share-2">
            <div class="tab-text">
              <user-edit-tab-social class="mt-4" :data="user_data" />
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"
import UserEditTabSocial      from "./UserEditTabSocial.vue"

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'
import { mapActions } from 'vuex';

export default {
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
  },
  data() {
    return {
      user_data: {
          newUser: true,
          _id: '',
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          status: 'Enabled',
          tutoringLocation: {
              displayAddress: '',
              placeName: '',
              state: '',
              location: {
                  coordinates: []
              }
          },
          rate: '',
          wwcc:'',
          isWWCCVerified:false,
          dateOfBirth: '',
          contactNumber: '',
          languages: [],
          subjects: [],
          //2nd Tab
          workExperiences: [],
          qualifications: [],
          //3rd tab
          availabilities: [
              {
                  day: 'sunday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }
                  ]
              },
              {
                  day: 'monday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }]
              },
              {
                  day: 'tuesday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }]
              },
              {
                  day: 'wednesday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }]
              },
              {
                  day: 'thursday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }]
              },
              {
                  day: 'friday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }]
              },
              {
                  day: 'saturday',
                  availableTimes: [
                    {
                      startTime: null,
                      endTime: null
                    }]
              },
              ],
          availabilitiesTimeZone:'',
      },
      user_not_found: false,
      activeTab: 0,
    }
  },
  computed:{
      newUserId(){
        console.log(this.$store.state.tutor._id)
        return this.$store.state.tutor._id
      }
  },
  watch: {
    // activeTab() {
    //   let self = this;
    // //   this.fetchTutorById(self.$route.params.tutorId).then(res => {
    // //     self.user_data = res.data.data;
    // //   })
    // }
  },
  methods: {
    ...mapActions('tutor', ['fetchTutorById']),
  },
  async mounted() {
      // await this.getTutorDetails()
  }
}

</script>
